import { h } from "preact";
import { Link } from "preact-router/match";
import { HStack, Heading, Image } from "@chakra-ui/react";

const Header = () => (
  <HStack
    as="header"
    justifyContent="space-between"
    mb={5}
    bg="brand.300"
    px={4}
    py={2}
  >
    <HStack>
      <Image src="assets/logo-transparent.png" boxSize={10} />
      <Heading as="h1" textColor="white" size="md" ml={0}>
        Teamperature
      </Heading>
    </HStack>
    <Link href="/">Home</Link>
  </HStack>
);

export default Header;
