import { h } from "preact";
import { Router } from "preact-router";
import { ChakraProvider } from "@chakra-ui/react";

import Header from "./header";

// Code-splitting is automated for `routes` directory
import Home from "../routes/home";
import Profile from "../routes/city";
import theme from "../style/style";

const App = () => (
  <ChakraProvider theme={theme}>
    <div id="app">
      <Header />
      <Router>
        <Home path="/" />
        <Profile path="/city/:city" />
      </Router>
      <footer></footer>
    </div>
  </ChakraProvider>
);

export default App;
