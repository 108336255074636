import { extendTheme, theme as base } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    // generated using http://mcg.mbitson.com/ with #60c142
    brand: {
      50: "#ecf8e8",
      100: "#cfecc6",
      200: "#b0e0a1",
      300: "#90d47b",
      400: "#78ca5e",
      500: "#60c142",
      600: "#58bb3c",
      700: "#4eb333",
      800: "#44ab2b",
      900: "#339e1d",
      // 50: "#e6e5ef",
      // 100: "#c0bfd6",
      // 200: "#9695bb",
      // 300: "#6c6a9f",
      // 400: "#4d4a8b",
      // 500: "#2d2a76",
      // 600: "#28256e",
      // 700: "#221f63",
      // 800: "#1c1959",
      // 900: "#110f46",
    },
  },

  fonts: {
    heading: `Source Code Pro, ${base.fonts.heading}`,
  },
});

export default theme;
